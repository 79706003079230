import { ApolloClient, createHttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import getConfig from '@/config'
import * as Sentry from '@sentry/nextjs'

const siteConfig = getConfig().publicRuntimeConfig

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient = null

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext} ctx
 */
export const initApolloClient = (initialState, ctx) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx)
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, ctx)
  }

  return globalApolloClient
}

// The `ctx` (NextPageContext) will only be present on the server.
// use it to extract auth headers (ctx.req) or similar.
export default function createApolloClient(initialState, ctx) {
  const httpLink = createHttpLink({
    uri: siteConfig.graphqlUrl,
    credentials: 'include',
    fetch,
  })
  const retryLink = new RetryLink()

  const authLink = setContext((_, { req, headers }) => {
    return { headers: ctx?.req ? { cookie: ctx.req.headers.cookie } : {} }
  })

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
    if (graphQLErrors || networkError) {
      const extra = {
        operationName: rest?.operation?.operationName,
        variables: rest?.operation?.variables,
      }
      if (graphQLErrors)
        graphQLErrors.forEach(e =>
          Sentry.captureException(new Error(e.message), { extra })
        )
      if (networkError) Sentry.captureException(networkError, { extra })
    }
  })

  return new ApolloClient({
    connectToDevTools:
      siteConfig.env === 'local' || siteConfig.env === 'staging',
    ssrMode: Boolean(ctx),
    link: retryLink.concat(errorLink).concat(authLink.concat(httpLink)),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // Field policy for cms blog post pagination
            posts: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: false,
              merge(existing, incoming, { args: { offset = 0 } }) {
                const merged = existing ? existing.slice(0) : []
                for (let i = 0; i < incoming.length; ++i) {
                  merged[offset + i] = incoming[i]
                }
                return merged
              },
            },
            learning_featured_modules: {
              keyArgs: false,
              merge(existing, incoming, { args: { offset = 0 } }) {
                const merged = existing ? existing.slice(0) : []
                for (let i = 0; i < incoming.length; ++i) {
                  merged[offset + i] = incoming[i]
                }
                return merged
              },
            },
          },
        },
      },
    }).restore(initialState),
  })
}
